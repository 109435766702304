export function useGoogleMaps() {
  const googleInstance = ref<typeof google | undefined>(typeof google === 'undefined' ? undefined : google)
  const runtimeConfig = useRuntimeConfig()

  onMounted(() => {
    if (!window.initGoogleMaps) {
      window.initGoogleMaps = function () {
        googleInstance.value = window.google
      }
    }
  })

  if (!googleInstance.value) {
    useHead({
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=${runtimeConfig.public.googleMapApiKey}&libraries=places&loading=async&callback=initGoogleMaps`,
        async: true,
        key: 'google-maps-script',
      }],
    })
  }

  return googleInstance
}

declare global {
  interface Window {
    initGoogleMaps: () => void
  }
}
